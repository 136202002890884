.percentile-history-list {
    /* make the scroll bar appear on left */
    direction: rtl;
    height: 100%;
}


.percentile-history-list > * {
    /* Undo the transformation on the child elements */
    direction: ltr;
}