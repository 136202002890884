@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .break-inside-avoid {
    break-inside: avoid;
  }
}

body {
  margin: 0;
  font-family: 'M PLUS Rounded 1c', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/**
 * Remove default button styles.
 */
button {
  background-color: transparent;
  color: inherit;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  text-align: inherit;
}

button:not(:disabled) {
  cursor: pointer;
}

.font-mplus {
  font-family: 'M PLUS Rounded 1c', sans-serif;
}
