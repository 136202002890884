.page-side-panel {
  width: 100%;
  max-width: 231px;
}

.page-with-panel {
  width: 100%;
  max-width: calc(100% - 231px);
  margin-left: 231px;
}

@media print {
  .page-with-panel,
  .page {
    margin-left: unset;
    max-width: unset;
    display: block;
  }

  .page-side-panel {
    display: none;
  }
}
