.auth-side-panel {
    padding: 1.5rem 0 8.125rem 0;
}

.logo {
    height: 120px;
    width: 171px;
}


@media (max-height: 580px) {
    .side-panel-options {
        margin-top: 0;
    }
}