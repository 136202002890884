.dropdown-caret {
    height: 24px;
    width: 24px;
}

.dropdown-caret-large {
    height: 16px;
    width: 24px;
    margin-left: 1.5rem;
    position: relative;
    top: 10px;
}

.dropdown-menu {
    max-height: 216px;
}

.option-separator {
    border-top: 1px solid #E6E6E6;
}