
.student-performance-panel {
    max-height: 392px;
    width: 470px;
}


.student-risk-panel {
    padding: 1.5rem 1.5rem 2.25rem 1.5rem;
    max-height: 392px;
    width: 228px;
}