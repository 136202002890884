.school-detail-scroll {
    /* make the scroll bar appear on left */
    direction: rtl;
    height: 100%;
}


.school-detail-scroll > * {
    /* Undo the transformation on the child elements */
    direction: ltr;
}