.dashboard-list {
    /* make the scroll bar appear on left */
    direction: rtl;
    height: 100%;
}


.dashboard-list > * {
    /* Undo the transformation on the child elements */
    direction: ltr;
}

.dashboard-list-min-width {
    min-width: 730px;
}